(function($) {
    Wallet.Wizard.serverErrorDisplay = function(settings) {
        return new ServerErrorDisplay(settings);
    };

    Wallet.Wizard.formFieldsUpdateDetection = function(settings) {
        return new FormFieldsUpdateDetection(settings);
    };

    Wallet.Wizard.completionMessage = function(settings) {
        return new CompletionMessage(settings);
    };

    Wallet.Wizard.formManagement = function(settings) {
        return new Wallet.Wizard.FormManagement(settings);
    };

    Wallet.Wizard.stepMovement = function (settings) {
        return new Wallet.Wizard.StepMovement(settings);
    };

    /////////////////////////

    var ServerErrorDisplay = function(settings) {
        this.fieldsToClear = Wallet.Wizard.Utilities.stepBindingsSetting(settings, 'fieldsToClear', {});
    };
    ServerErrorDisplay.prototype = {
        bind: function(stepContainer, step) {
            var form = stepContainer.find("form");
            var topLevelErrorMessage = form.find(".alert-danger");
            var self = this;
            step.registerCallbacks({
                error: function (payload) {
                    var serverErrors = payload.errors;
                    _.each(serverErrors, function(errorMessages, errorField) {
                        var field = form.find("*[name='" + errorField +"']");
                        _.each(errorMessages, function(errorMessage) {
                            var errorDiv = $("<p>").addClass("form-error").html(errorMessage);
                            if(field.length !== 0) { field.after(errorDiv); }
                            else { topLevelErrorMessage.append(errorDiv); }
                            errorDiv.isHidden(false);
                            field.off('focusout.serverError').on('focusout.serverError', function () {
                                field.siblings("p.form-error").remove();
                                topLevelErrorMessage.isHidden(true);
                            });
                        });
                    });
                    topLevelErrorMessage.isHidden(false);
                    _.each(self.fieldsToClear, function(field) { field.val('') });
                }
            });
        }
    };

    /////////////////////////

    var FormFieldsUpdateDetection = function(settings) {
        this.updateDetected = Wallet.Wizard.Utilities.stepBindingsSetting(settings, 'updateDetected', $.noop);
    };
    FormFieldsUpdateDetection.prototype = {
        bind: function(stepContainer, step) {
            var self = this;
            step.registerCallbacks({
                success: function() {
                    self._detectUpdate(stepContainer.find("form"));
                }
            });
        },
        _detectUpdate: function(form) {
            if (this._updateDetectedOn(form)) {
                this.updateDetected();
            }
        },
        _updateDetectedOn: function(form) {
            // limitation: not accounting for checkbox/radio buttons; not needed yet
            var fields = form.find('*[name]'); // assumption: name attribute only used for fields within a form
            return _.any(fields, function(elementDom) {
                var element = $(elementDom);
                var originalValue = (element.prop('tagName').toLowerCase() == "select" ? element.find('option[selected]').val() : element.attr('value')) || '';
                if(originalValue === '') {
                    return false;
                }
                var currentValue = element.val() || '';
                return currentValue != originalValue;
            });
        }
    };

    /////////////////////////

    var CompletionMessage = function(settings) {
        this.signUpResultMessages = Wallet.Wizard.Utilities.stepBindingsSetting(settings, 'signUpResultMessages', {});
    };

    CompletionMessage.prototype = {
        bind: function(stepContainer, step) {
            var self = this;
            step.registerCallbacks({
                success: function(payload) {
                    self._showMessage(payload.data);
                }
            });
        },
        _showMessage : function(data) {
            if (_.isEmpty(data)) {
                return;
            }
            var message = this.signUpResultMessages[data.sign_up_result];
            if (!message) {
                return;
            }
            message.isHidden(false);
        }
    };

    /////////////////////////

})(jQuery);
