window.Wallet = window.Wallet || {};
window.Wallet.Wizard = {};

$.fn.isHidden = function (value) {
    $(this).toggleClass('is-hidden', value);
};

$.fn.moveTo = function (scrollPosition, scrollSpeed) {
    var speed = scrollSpeed || 500;
    $(this).animate({
        scrollTop: scrollPosition
    }, speed);
};

jQuery(function () {
    $(".js-disablePaste").bind('paste', function (e) {
        e.preventDefault();
    });

    $(".js-submitOnce").bind('submit', function (event) {
        var form = $(this);
        if (form.valid()) {
            if (form.hasClass('form-submitted')) {
                event.preventDefault();
                return;
            }
            form.addClass('form-submitted');
        }
    });

    $('.js-submitInsideForm').on('click', function () {
        var form = $(this).closest('form');
        if (form.valid()) {
            form.submit();
        }
    });


    //BOOTSTRAP MODULES
    $('[data-toggle="tooltip"]').tooltip()
});
