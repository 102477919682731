Wallet.Wizard.Utilities = {
    createStepWith: function (stepContainer, bindings, stepType) {
        var step = new Wallet.Wizard.Step(stepType);
        _.each(bindings, function (binding) {
            binding.bind(stepContainer, step);
        });
        return step;
    },
    stepBindingsSetting: function(settings, settingName, defaultValue) {
        settings = settings || {};
        return settings.hasOwnProperty(settingName) ? settings[settingName] : defaultValue;
    }
};
