Wallet.formValidations = {
    validationDefaults: {
        onkeyup: $.noop,
        onfocusout: $.noop,
        errorElement: "p",
        errorClass: "form-error"
    },
    formValidate: function (form, settings) {
        var _settings = Object.assign({}, this.validationDefaults, settings);

        form.each(function (_, e) {
            $(e).validate(_settings);
        });
    },
    handleLegalStatus: $.noop
};

Wallet.formValidations.handleLegalStatus.prototype = {
    init: function (selectBox, elements, form) {
        this.selectBox = selectBox;
        this.elements = elements;
        this.form = form;
        this._handleSelectBox();
    },
    _handleSelectBox: function () {
        var self = this,
            to_toggle = false;

        self.selectBox.change(function () {
            var required = (self.selectBox.find('option:selected').attr('trading_info_required') === 'true');
            if (required !== to_toggle) {
                $.each(self.elements, function (i, element) {
                    $("input[name='" + element + "']").prev().toggleClass('required');
                });
                to_toggle = !to_toggle;
            }

            $.each(self.elements, function (i, element) {
                $("input[name='" + element + "']").next().remove();
            });
        });
    },
    rules: function () {
        var self = this,
            rules = {};

        function trading_info_required(direction) {
            return function () {
                var company_legal_status_element = self.selectBox.find(" option:selected"),
                    current = company_legal_status_element.attr('trading_info_required') === 'true';

                return current === direction;
            };
        }

        $.each(self.elements, function (i, element) {
            rules[element] = {required: {depends: trading_info_required(!i)}};
        });

        return rules;
    }
};


$.validator.setDefaults(Wallet.formValidations.validationDefaults);
jQuery(function () {
    Wallet.Utilities.guardWith(jQuery('.js-formValidation'), function () {
        Wallet.formValidations.formValidate($('.js-formValidation'), {
            onfocusout: function (element) {this.element(element)}
        });
    });

    Wallet.Utilities.guardWith(jQuery('.onkeyup-validation-form'), function () {
        Wallet.formValidations.formValidate($('.onkeyup-validation-form'), {
            onkeyup: function (element) {this.element(element)}
        });
    });
});

