(function ($) {
    Wallet.Wizard.StepMovement = function (settings) {
        this.stepProgressIndicator = Wallet.Wizard.Utilities.stepBindingsSetting(settings, 'stepProgressIndicator');
    };
    Wallet.Wizard.StepMovement.prototype = {
        bind: function (stepContainer, step) {
            var self = this;
            step.registerCallbacks({
                init: function () {
                    stepContainer.find('.back').click(function () {
                        step.back();
                    });

                    stepContainer.find('.next').click(function () {
                        $(".js-serverMessage").isHidden(true);
                        step.success();
                    });

                    stepContainer.find('.process').click(function (e) {
                        e.preventDefault();
                        step.process();
                    });
                },
                show: function (payload) {
                    if (step.type == "slide"){
                        new Wallet.Wizard.SliderMovement(stepContainer).show();
                    }else{
                        stepContainer.isHidden(false);
                    }

                    if (self.stepProgressIndicator) {
                        self.stepProgressIndicator.removeClass("is-completed");
                        self.stepProgressIndicator.addClass("is-inProgress");
                    }
                    if (payload && payload.location) {
                        stepContainer.find("form").attr('action', payload.location);
                    }
                },
                hide: function () {
                    if (self.stepProgressIndicator) {
                        self.stepProgressIndicator.removeClass("is-inProgress");
                    }
                    if (step.type == "slide"){
                        new Wallet.Wizard.SliderMovement(stepContainer).hide();
                    }else{
                        stepContainer.isHidden(true);
                    }
                },
                success: function () {
                    if (self.stepProgressIndicator) {
                        self.stepProgressIndicator.addClass("is-completed");
                    }
                }
            });
        }
    };

    Wallet.Wizard.SliderMovement = function(container){
        this.container = container;
    };
    Wallet.Wizard.SliderMovement.prototype = {
        show: function(){
            this.container.show();
        },
        hide: function(){
            this.container.hide();
        }
    };

})(jQuery);
