Wallet.Utilities = {
    guardWith: function (element, code) {
        if (element.length !== 0) {
            code();
        }
    },

    isMobile: function () {
        return $(window).width() <= 768;
    },

    setScrollbar(target, compareTarget) {
        var _target, _compareTarget;
        $(target).each(function(){
            _target = $(this);
            _compareTarget = _target.find($(compareTarget));
            (_target.width() < _compareTarget.width()) ? _target.addClass('table-scrollbar--active') : _target.removeClass('table-scrollbar--active')
        })
    },

    delay(callback, ms) {
        var timer = 0;
        return function () {
            var context = this, args = arguments;
            clearTimeout(timer);
            timer = setTimeout(function () {
                callback.apply(context, args);
            }, ms || 0);
        };
    }
};
