Wallet.Cards = function (card) {
    this.card = card;
};

Wallet.Cards.prototype = {
    init: function(){
        this.onDocumentLoad(this.card);
        this.onCheckedHandle(this.card);
    },
    onCheckedHandle: function (card) {
        $(card + ' input[type=radio]').change(function(){
            $(card).removeClass('checked');
            $(this).parents(card).addClass('checked');
        });
    },
    onDocumentLoad: function(card){
        $(card + ' input[type=radio]:checked').parents(card).addClass('checked');
    }
};

jQuery(function(){
    new Wallet.Cards('.card-credit-card').init();
    new Wallet.Cards('.card-ezeewallet').init();
    new Wallet.Cards('.card-bank-account').init();
    new Wallet.Cards('.payment-card-wrapper--bank').init();
    new Wallet.Cards('.apm-wrapper').init();
});
