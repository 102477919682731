Wallet.Messages = function (scrollingElement) {
    this.scrollingElement = scrollingElement ? scrollingElement : $('body');
};

Wallet.Messages.prototype = {
    goToMessage: function (message, speed, exactPosition) {
        var self = this;
        if (message.length) {

            var offsetTop = typeof exactPosition === 'number' ? exactPosition : message.offset().top;
            self.scrollingElement.moveTo(offsetTop, speed);

        }
    }
};
