(function ($) {
    Wallet.Wizard.FormManagement = function (settings) {
        this.validations = Wallet.Wizard.Utilities.stepBindingsSetting(settings, 'validations', {});
        this.submissionType = Wallet.Wizard.Utilities.stepBindingsSetting(settings, 'submissionType');
        this.formType = Wallet.Wizard.Utilities.stepBindingsSetting(settings, 'formType');
    };

    Wallet.Wizard.FormManagement.prototype = {
        bind: function (stepContainer, step) {
            var self = this;
            var form = this.formType == "parent" ? stepContainer.parents("form") : stepContainer.find("form");
            step.registerCallbacks({
                init: function () {
                    function associatedElements(element) {
                        var elementName = element.attr('name');
                        var validator = form.validate();
                        var groups = validator.groups;
                        if (!_.has(groups, elementName)) {
                            return [element];
                        }
                        var groupName = groups[elementName];
                        var groupedElements = groups.chain().pairs()
                            .reduce(function (memo, e) {
                                var elementName = e[0], elementGroup = e[1];
                                memo[elementGroup] = memo[elementGroup] || [];
                                memo[elementGroup].push($("*[name='" + elementName + "']"));
                                return memo;
                            }, {})
                            .value();
                        return groupedElements[groupName];
                    }

                    var validationSettings = $.extend({
                        onfocusout: function (element) {
                            var applicableElements = associatedElements($(element));
                            _.find(applicableElements, function (applicableElement) {
                                // calling 'valid' on a valid element clears
                                // any previous error message displayed, so need 'find'
                                return !applicableElement.valid();
                            });
                        },
                        submitHandler: function () {
                            if (self.submissionType == "ajax") {
                                new Wallet.Wizard.AjaxFormSubmission(step, form).submit();
                            } else {
                                form[0].submit();
                            }
                        },
                        invalidHandler: function () {
                            step.refresh();
                        }
                    }, self.validations);
                    form.validate(validationSettings);
                    form.find('a.submit').click(function () {
                        form.submit();
                    });
                },
                back: function () {
                    form.get(0).reset();
                    form.validate().resetForm();
                },
                process: function () {
                    form.find(".js-serverMessage").isHidden(true);
                    if (form.valid()) {
                        step.success();
                    }
                }
            });
        },
        togglePasswordVisibility: function (trigger, target) {
            let toggle = false;
            $(trigger).on('click', function () {
                toggle = !toggle;
                $(this).toggleClass('password-is-hidden');
                $(target).attr('type', () => toggle ? 'text' : 'password').attr('autocomplete', 'off')
            })

            $(".toggle-password").click(function (e) {
                e.preventDefault();

                $(this).toggleClass("toggle-password");
                $(this).find('.fa-solid').toggleClass("hidden");

                var input = $($(this).attr("toggle"));
                if (input.attr("type") == "password") {
                   input.attr("type", "text");
                } else {
                   input.attr("type", "password");
                }
            });
        }
    };

    Wallet.Wizard.AjaxFormSubmission = function (step, form) {
        this.step = step;
        this.form = form;
    };
    Wallet.Wizard.AjaxFormSubmission.prototype = {
        submit: function () {
            var basicAjaxSettings = {
                url: this.form.attr('action'),
                type: this.form.attr('method'),
                data: this.form.serialize()
            };
            var ajaxSettings = this._addAjaxCallbacks(basicAjaxSettings);
            if (_.isEmpty(this.step.previousCall) || this.step.previousCall.readyState === 4) {
                this._inProgressCall();
                this.step.previousCall = $.ajax(ajaxSettings);
            }
        },
        _addAjaxCallbacks: function (basicAjaxSettings) {
            var self = this;
            return $.extend({}, basicAjaxSettings, {
                success: function (data, textStatus, xhr) {
                    var payload = {
                        location: xhr.getResponseHeader('Location') || basicAjaxSettings.url,
                        data: data
                    };
                    self._completedCall();
                    self.step.success(payload);
                },
                error: function (xhr) {
                    var payload = {errors: $.parseJSON(xhr.responseText)};
                    self._completedCall();
                    self.step.error(payload);
                }
            });
        },
        _inProgressCall: function () {
            Wallet.Spinner.on(this.form.find('button.submit'));

        },
        _completedCall: function () {
            Wallet.Spinner.off(this.form.find('button.submit'));
        }
    };
})(jQuery);

jQuery(function () {
    new Wallet.Wizard.FormManagement().togglePasswordVisibility('.js-toggle-view-password', '.js-password-view-to-be-toggled')
});
