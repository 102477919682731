Wallet.Spinner = {
    presets: function() {
        $.fn.spin.presets.presetInline = {
            lines: 9,
            length: 0, // The length of each line
            width: 4, // The line thickness
            radius: 6,
            corners: 1, // Corner roundness (0..1)
            rotate: 0, // The rotation offset
            direction: 1, // 1: clockwise, -1: counterclockwise
            speed: 1,
            trail: 62, // Afterglow percentage
            zIndex: 999,
            top: '50%',
            left: '50%'
        };
    },
    on: function(container, options) {
        options = options || {};
        var preset = options.preset || 'presetInline';
        var color = options.color || '#fff';

        var spinnerWrapper = '<span class="spinnerWrap"></span>',
            spinnerWrapperElem = container.find('.spinnerWrap');

        if (spinnerWrapperElem.length === 0) {
            $(spinnerWrapper).prependTo(container);
            container.find('.spinnerWrap').spin(preset, color);
        }
    },
    off: function(container) {
        container.spin(false);
        container.find('.spinnerWrap').remove();
    }
};

jQuery(function() {
    Wallet.Spinner.presets();
});
