require.context('./assets/images', true);

import 'javascripts/common/initialisers';

import 'javascripts/common/wizard/commonStepBindings';
import 'javascripts/common/wizard/Flow';
import 'javascripts/common/wizard/formManagement';
import 'javascripts/common/wizard/Step';
import 'javascripts/common/wizard/stepMovement';
import 'javascripts/common/wizard/utilities';

import 'javascripts/common/customValidations';
import 'javascripts/common/formValidation';
import 'javascripts/common/hideErrorMessage';
import 'javascripts/common/Messages';
import 'javascripts/common/Spinner';
import 'javascripts/common/utilities';
import 'javascripts/common/Cards';

import './bundles/utils/globals';
