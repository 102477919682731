Wallet.HideErrorMessage = function(options) {
    this.options = jQuery.extend({}, this.defaults, options);
    this.attributes = options.attributes;
};

Wallet.HideErrorMessage.prototype = {
    process: function() {
        _.each(this.attributes, function(attr) {
             attr.on('focusin focusout', function(event) {
                 event.stopImmediatePropagation();
                 if($(this).parent().find('p.form-error')) {
                    $(this).parent().find('p.form-error').remove();
                 }
             });
        });
    }
};
