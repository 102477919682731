Wallet.Wizard.Flow = function (steps) {
    this.steps = steps;
    var self = this;
    _.each(steps, function (step) {
        step.registerCallbacks({
            success: function (payload) {
                self.stepSuccessful(step, payload);
            },
            back: function () {
                self.stepBack(step);
            }
        });
        step.init();
    });
};

Wallet.Wizard.Flow.prototype = {
    stepSuccessful: function (step, payload) {
        var nextStep = this._next(step);
        if(nextStep.type != Wallet.Wizard.StepType.OVERLAY) {
            step.hide();
        }
        nextStep.show(payload);
    },
    stepBack: function (step) {
        step.hide();
        this._previous(step).show();
    },
    _next: function(step){
        var currentIndex = this._currentIndex(step);
        var nextIndex = (currentIndex + 1);
        return nextIndex >= this.steps.length ? Wallet.Wizard.Step.NULL : this.steps[nextIndex];
    },
    _previous: function (step) {
        var currentIndex = this._currentIndex(step);
        var previousIndex = (currentIndex - 1);
        return previousIndex < 0 ? Wallet.Wizard.Step.NULL : this.steps[previousIndex];
    },
    _currentIndex: function (step) {
        return _.indexOf(this.steps, step);
    }
};
